var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12 } },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_product")) + ": ")
              ]),
              _c("a-input-search", {
                attrs: {
                  placeholder: _vm.$t("lbl_search_here"),
                  "allow-clear": "",
                  "enter-button": ""
                },
                on: { search: _vm.onsearchProduct },
                model: {
                  value: _vm.form.product,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "product", $$v)
                  },
                  expression: "form.product"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "download" },
                  on: { click: _vm.showModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtListProduct,
                    scroll: { y: 500 },
                    pagination: false,
                    loading: _vm.loading.list
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode", width: "250px" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName", width: "500px" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "bottomPrice",
                      attrs: { "data-index": "bottomPrice" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      (record.bottomPrice || 0) + ""
                                    ) || "0"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_bottom_price")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 16 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dtProductBottomPrice.totalElements,
                  "page-size-set": _vm.queryParam.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 8, align: "end" } },
            [
              _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dtProductBottomPrice.totalElements) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          model: {
            value: _vm.vmModal,
            callback: function($$v) {
              _vm.vmModal = $$v
            },
            expression: "vmModal"
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.$t("lbl_download_report")))
          ]),
          _c(
            "a-form-model",
            { attrs: { model: _vm.form, "label-align": "left" } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_period") } },
                [
                  _c("a-range-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      placeholder: [
                        _vm.$t("lbl_start_date"),
                        _vm.$t("lbl_end_date")
                      ]
                    },
                    model: {
                      value: _vm.form.period,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "period", $$v)
                      },
                      expression: "form.period"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: {
                    icon: "download",
                    type: "primary",
                    loading: _vm.loading.download
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }