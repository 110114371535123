import { ResponseListBottomPrice } from "@interface/product-bottom-price.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";
import { Api } from "@/models/class/api.class";

export class ProductBottomPriceServices extends HttpClient {
  constructor() {
    super();
  }

  getListBottomPrice(params: RequestQueryParamsModel): Promise<ResponseListBottomPrice> {
    return this.get<ResponseListBottomPrice>(Api.ProductBottomPrice, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadReportBottomPrice(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.ProductBottomPrice}/report/download`, { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  uploadReportBottomPrice(file: FormData): Promise<boolean> {
    return this.post<boolean, FormData>(`${Api.ProductBottomPrice}/upload`, file)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getUploadUrl(): string {
    return `${Api.ProductBottomPrice}/upload`;
  }
}

export const productBottomPriceService = new ProductBottomPriceServices();
