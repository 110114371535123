























































































































import { ResponseBottomPrice, ResponseListBottomPrice } from "@interface/product-bottom-price.interface";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import Vue from "vue";
import { productBottomPriceService } from "@service/product-bottom-price.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { formatCurrency } from "@/validator/globalvalidator";
import moment, { Moment } from "moment";

interface ITableRow {
  key: any;
  productCode: string;
  productName: string;
  bottomPrice: number;
}

export default Vue.extend({
  name: "ProductReportBottomPrice",
  mixins: [
    MNotificationVue
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        product: "",
        period: null as Moment[] | null
      },
      dtListProduct: [] as ITableRow[],
      dtProductBottomPrice: {} as ResponseListBottomPrice,
      loading: {
        list: false,
        download: false
      },
      queryParam: {
        limit: 20,
        page: 0,
      } as RequestQueryParamsModel,
      vmModal: false
    };
  },
  mounted() {
    this.fetchList(this.queryParam);
  },
  methods: {
    moment,
    formatCurrency,
    getListBottomPrice(params: RequestQueryParamsModel): Promise<ResponseListBottomPrice> {
      return productBottomPriceService.getListBottomPrice(params);
    },
    downloadReport(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
      return productBottomPriceService.downloadReportBottomPrice(params);
    },
    showModal(): void {
      this.vmModal = true;
    },
    async fetchList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.list = true;
        const res = await this.getListBottomPrice(params);
        this.dtProductBottomPrice = res;
        this.fillTable(this.dtProductBottomPrice.data);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.list = false;
      }
    },
    fillTable(res: ResponseBottomPrice[]): void {
      this.dtListProduct = res.map((x, i) => {
        return {
          bottomPrice: x.bottomPrice,
          key: i,
          productCode: x.code,
          productName: x.name
        };
      });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParam.page = 0;
      this.queryParam.limit = response.size;
      this.fetchList(this.queryParam);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParam.page = response.page - 1;
      this.fetchList(this.queryParam);
    },
    onsearchProduct(search = ""): void {
      if (search) {
        this.queryParam.search = `product.code~*${search}*_OR_product.name~*${search}*`;
        this.queryParam.page = 0;
        this.fetchList(this.queryParam);
      } else {
        this.queryParam.page = 0;
        delete this.queryParam.search;
        this.fetchList(this.queryParam);
      }
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading.download = true;
        const params: RequestQueryParamsModel = {
          page: 0,
          limit: 20,
        };
        const searchBy: string[] = [];
        if (this.form.period?.length) {
          const start = this.form.period[0].set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format();
          const end = this.form.period[1].set({hour: 23, minute: 59, second: 59}).utcOffset("+07").format();
          searchBy.push(`date>=${start}_AND_date<=${end}`);
        }
        if (this.form.product) {
          searchBy.push(`code~*${this.form.product}*_OR_name~*${this.form.product}*`);
        }
        if (searchBy.length) params.search = searchBy.join("_AND_");
        const arrb = await this.downloadReport(params);
        const url = window.URL.createObjectURL(new Blob([arrb]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report_Product_Bottom_Price.xlsx"); //or any other extension
        link.click();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.download = false;
      }
    }
  }
});
